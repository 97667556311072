(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name category.controller:CategoryCtrl
   *
   * @description
   *
   */
  angular
    .module('category')
    .controller('CategoryCtrl', CategoryCtrl);

  function CategoryCtrl($scope, $stateParams, $state, $timeout, $rootScope, $mdDialog, Category, MyOrder) {

    $rootScope.pageTitle = "תפריט הזמנות";
    $scope.categoryId = $stateParams.categoryId;

    $scope.currentCategoryItems = [];


    $scope.changeParams = function(id) {
      $state.go('category', {
        categoryId: id
      }, {
        notify: false
      });
      $scope.categoryId = id;
      $scope.setCurrentItems();
      $scope.slideUp();
    }

    $scope.setCurrentItems = function() {
      if ($scope.categoryId == 0) { //pizza
        $scope.currentCategoryItems = $scope.menu.pizzas;
      } else if ($scope.categoryId == -1) { //favorites
        $scope.currentCategoryItems = []; //TODO
      } else {
        $scope.currentCategoryItems = _.find($scope.menu.categories, function(e) {
          return e.Id == $scope.categoryId
        }).Items;
      }
    }

    $scope.initCategoriesCarousel = function() {
      $timeout(function() {
        $(".categories-menu").slick({
          infinite: false,
          slidesToShow: 6,
          slidesToScroll: 6,
          arrows: false
        })
      }, 100);
    }();

    $scope.addItemToOrder = function(item) {
      MyOrder.addItemToOrder(angular.copy(item));
    }


    //-------------------------------------------------------------------
    //--------------------Order item functions---------------------------
    //-------------------------------------------------------------------
    $scope.currentItem = {};
    $scope.selectItem = function(item) {
      $scope.currentItem = angular.copy(item);
      $scope.currentItem.toppings = [];
        $scope.currentItem.amount = 1;
      $mdDialog.show({
        templateUrl: 'dialogs/menuGarnishes.html',
        scope: $scope,
        preserveScope: true
      });
    }

    $scope.selectGarnish = function(garn) {
      garn.selected = !garn.selected;
    }

    $scope.addItemWithGarnishesToOrder = function() {
      var garnishesPrice = 0;
      $scope.currentItem.Garnishes.forEach(function(garn) {
        if (garn.selected) {
          garn.selected = false;
          $scope.currentItem.toppings.push(garn);
          garnishesPrice += garn.Price;
        }
      });
      $scope.currentItem.Price += garnishesPrice;
      MyOrder.addItemToOrder($scope.currentItem);
      $scope.hideDialog();
      $scope.slideUp();
    }

    //-------------------------------------------------------------------
    //--------------------Order Pizza functions--------------------------
    //-------------------------------------------------------------------

    $scope.selectPizza = function(pizza) {
      $scope.currentPizza = pizza;
      $scope.currentPizza.amount = 1;
      $scope.currentPizza.toppings = [];
      if (pizza.PizzaPrices.length > 0) {
        $scope.selectPizzaSize(pizza.PizzaPrices[0]);
      }
      $mdDialog.show({
        templateUrl: 'dialogs/pizzaDetails.html',
        scope: $scope,
        preserveScope: true
      });
    }

    $scope.selectPizzaSize = function(pizzaPrice) {
      $scope.currentPizza.pizzaPrice = pizzaPrice;
      $scope.pizzaToppings.forEach(function(topping) {
        var toppingPrice = _.find(topping.ToppingPrices, function(e) {
          return e.PizzaSizeId == pizzaPrice.PizzaSizeId
        });
        topping.currentPrice = toppingPrice.Price;
        var quarterPrice = toppingPrice.Price / 4;

        $scope.currentPizza.toppings.forEach(function(top) {
          top.totalPrice = quarterPrice * top.quarterNums.length;
          if (top.toppingId == topping.Id) topping.totalPrice = quarterPrice * top.quarterNums.length;
        })

      })
    }

    $scope.selectQuarter = function(quarterNum, topping) {
      switch (quarterNum) {
        case 0:
          if (!topping.firstQuarter) {
            topping.firstQuarter = true;
            topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.firstQuarter, topping.Id, 1, topping.currentPrice / 4, topping.Name);
          } else if (!topping.secondQuarter) {
            topping.secondQuarter = true;
            topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.secondQuarter, topping.Id, 2, topping.currentPrice / 4, topping.Name);
          } else if (!topping.thirdQuarter) {
            topping.thirdQuarter = true;
            topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.thirdQuarter, topping.Id, 3, topping.currentPrice / 4, topping.Name);
          } else if (!topping.forthQuarter) {
            topping.forthQuarter = true;
            topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.forthQuarter, topping.Id, 4, topping.currentPrice / 4, topping.Name);
          }
          break;
        case 1:
          topping.firstQuarter = !topping.firstQuarter;
          topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.firstQuarter, topping.Id, quarterNum, topping.currentPrice / 4, topping.Name);
          break;
        case 2:
          topping.secondQuarter = !topping.secondQuarter;
          topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.secondQuarter, topping.Id, quarterNum, topping.currentPrice / 4, topping.Name)
          break;
        case 3:
          topping.thirdQuarter = !topping.thirdQuarter;
          topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.thirdQuarter, topping.Id, quarterNum, topping.currentPrice / 4, topping.Name)
          break;
        case 4:
          topping.forthQuarter = !topping.forthQuarter;
          topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.forthQuarter, topping.Id, quarterNum, topping.currentPrice / 4, topping.Name)
          break;
        default:
      }
    }

    $scope.removeOrAddToppingToPizza = function(isAdd, toppingId, quarterNum, quarterPrice, toppingName) {
      if (isAdd) {
        var topping = _.find($scope.currentPizza.toppings, function(e) {
          return e.toppingId == toppingId
        })
        if (topping != undefined) {
          topping.quarterNums.push(quarterNum)
          topping.totalPrice = quarterPrice * topping.quarterNums.length;
          return quarterPrice * topping.quarterNums.length;
        } else {
          $scope.currentPizza.toppings.push({
            toppingId: toppingId,
            quarterNums: [quarterNum],
            totalPrice: quarterPrice,
            Name: toppingName
          })
          return quarterPrice;
        }

      } else {
        var topping = _.find($scope.currentPizza.toppings, function(e) {
          return e.toppingId == toppingId
        })
        var index = _.findIndex(topping.quarterNums, function(e) {
          return e == quarterNum
        });
        if (index >= 0) {
          topping.quarterNums.splice(index, 1);
        }
        topping.totalPrice = quarterPrice * topping.quarterNums.length;
        return topping.totalPrice;
      }
    }

    $scope.amountChanged = function(pizza) {
      if (pizza.amount == undefined) return;
      if (pizza.amount > 50) pizza.amount = 50;
      if (pizza.amount < 1) pizza.amount = 1;
    }

    $scope.addOne = function(pizza) {
      if (pizza.amount < 50) pizza.amount = pizza.amount + 1;
      if (pizza.amount == undefined) pizza.amount = 1;
    }
    $scope.subOne = function(pizza) {
      if (pizza.amount > 1) pizza.amount = pizza.amount - 1;
      if (pizza.amount == undefined) pizza.amount = 1;
    }

    $scope.addPizzaToOrder = function() {
      MyOrder.addPizzaToOrder(angular.copy($scope.currentPizza));
      $scope.hideDialog();
    }

    $scope.hideDialog = function() {
      $scope.pizzaToppings.forEach(function(top) {
        top.firstQuarter = false;
        top.secondQuarter = false;
        top.thirdQuarter = false;
        top.forthQuarter = false;
        top.currentCalcPrice = undefined;
        top.totalPrice = undefined;
      })
      $mdDialog.hide();
    }

    $rootScope.$on("menuReady", $scope.setCurrentItems);

    if ($scope.menu != undefined) $scope.setCurrentItems();

  }
}());
